import { TweenMax } from "gsap";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import AccordionArrowDown from "@/assets/icons/accordion-arrow-down-white.svg";
import AccordionArrowUp from "@/assets/icons/accordion-arrow-up-black.svg";

import styles from "./index.module.scss";

class ThreeColumnExpander extends PureComponent {
  constructor(props) {
    super(props);
    this.descriptionContainer = null;
    this.toggleAnimation = this.toggleAnimation.bind(this);
  }
  componentDidMount() {
    const { selectedClass, id } = this.props;
    if (selectedClass !== id) {
      TweenMax.to(this.descriptionContainer, 0, { height: 0, opacity: 0 });
    }
  }
  UNSAFE_componentWillUpdate(nextProps) {
    const { id } = this.props;
    if (nextProps.selectedClass === id) {
      this.toggleAnimation(true);
      return true;
    }

    this.toggleAnimation(false);
    return true;
  }
  toggleAnimation(open) {
    if (open) {
      TweenMax.set(this.descriptionContainer, { height: "auto", opacity: 1 });
      TweenMax.from(this.descriptionContainer, 0.8, { height: 0, opacity: 0 });
    } else {
      TweenMax.to(this.descriptionContainer, 0.8, { height: 0, opacity: 0 });
    }
  }
  render() {
    const {
      id,
      category,
      title,
      description,
      featuredTag,
      selectClass,
      selectedClass,
    } = this.props;
    const active = selectedClass === id;
    const labelValue = `${active ? "Close" : "Open"} ${title}`;
    return (
      <div
        alt={labelValue}
        aria-expanded={active}
        className={`${styles.threeColumnExpander} ${
          active ? styles.threeColumnExpanderActive : ""
        }`}
        onClick={() => {
          if (id === selectedClass) {
            return;
          }
          selectClass(id);
        }}
        onKeyPress={({ which }) => {
          if (which !== 13) {
            return;
          }
          selectClass(id);
        }}
        onMouseEnter={() => {
          if (id === selectedClass) {
            return;
          }
          selectClass(id);
        }}
        role="button"
        tabIndex={0}
      >
        <div className={`${styles.contentContainer} container`}>
          <div className="row">
            <div
              className={`${styles.category} ${
                active ? styles.active : ""
              } col-md-5 d-flex align-items-center order-2 order-md-1`}
            >
              <span>{category}</span>
            </div>
            <div
              className={`${styles.title} ${
                active ? styles.active : ""
              } col-14 col-md-7 col-lg-8 order-3 order-md-2`}
            >
              {title}
            </div>
            <div
              className={`${styles.featuredTag} ${
                active ? styles.active : ""
              } col-md-3 col-lg-2 d-flex align-items-center order-1 order-md-3`}
            >
              <span
                className={`${styles.featuredTagBox} ${
                  featuredTag ? "d-inline" : "d-none"
                } ${active ? styles.active : ""}`}
              >
                {featuredTag}
              </span>
            </div>
            <div
              className={`${styles.arrowContainer} col-2 col-md-1 d-flex align-items-center order-4 d-lg-none`}
            >
              {active ? <AccordionArrowUp /> : <AccordionArrowDown />}
            </div>
          </div>
          <div className="row">
            <div
              className={`${
                styles.description
              } col-md-8 offset-md-5 col-sm-16 ${active ? styles.active : ""}`}
              ref={(e) => (this.descriptionContainer = e)}
            >
              {description}
            </div>
          </div>
        </div>
        <div
          className={`${styles.rowBackground} ${
            active ? styles.rowActive : ""
          }`}
        ></div>
      </div>
    );
  }
}

ThreeColumnExpander.propTypes = {
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredTag: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  selectClass: PropTypes.func.isRequired,
  selectedClass: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default ThreeColumnExpander;
