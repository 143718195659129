import PropTypes from "prop-types";
import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Collapse } from "reactstrap";

import { baseEnvironmentConfig } from "@/api/constants";
import Utilities from "@/utils/helpers/formatting";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import defaultStyles from "./index.module.scss";

const Accordion = ({
  label,
  styles,
  accordionID,
  selectedAccordion,
  selectAccordionFunc,
  children,
  className = "",
  backgroundColor = "black",
  isMobile,
}) => {
  const isOpen = accordionID === selectedAccordion;
  const labelValue = `${isOpen ? "Close" : "Open"} ${label}`;

  const moduleStyles = { ...defaultStyles, ...styles };

  const {
    accordion,
    accordionLabelWrap,
    accordionLabel,
    accordionLabelArrow,
    accordionLabelArrowUp,
    accordionLabelArrowDown,
    accordionContents,
  } = moduleStyles;
  return (
    <div
      alt={labelValue}
      aria-expanded={isOpen}
      aria-label={labelValue}
      className={`${accordion} ${className} ${backgroundColor}`}
      data-testid={`${baseEnvironmentConfig.testIds.accordion}-${accordionID}`}
      onKeyPress={({ which }) => {
        if (which !== 13) {
          return;
        }
        selectAccordionFunc(accordionID);
      }}
      role="button"
      tabIndex={0}
    >
      <div
        aria-labelledby={Utilities.toCamelCase(`${accordionID}-header`)}
        className={accordionLabelWrap}
        onClick={() => {
          if (isOpen) {
            selectAccordionFunc("");
          } else {
            selectAccordionFunc(accordionID);
          }
        }}
      >
        <h3
          className={accordionLabel}
          id={Utilities.toCamelCase(`${accordionID}-header`)}
        >
          {label}
        </h3>
        <button
          aria-label={accordionLabelArrowUp}
          className={`${accordionLabelArrow} ${
            isOpen ? accordionLabelArrowUp : accordionLabelArrowDown
          }`}
          name="Accordion - Arrow Down"
          tabIndex={-1}
        >
          <IoIosArrowDown
            aria-hidden={!isMobile}
            color={backgroundColor === "white" ? "black" : "white"}
            style={{ fontSize: "20px" }}
          />
        </button>
      </div>
      <Collapse className={accordionContents} isOpen={isOpen}>
        {children}
      </Collapse>
    </div>
  );
};

Accordion.propTypes = {
  accordionID: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectAccordionFunc: PropTypes.func.isRequired,
  selectedAccordion: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

export default withScreenDimensions(Accordion);
