import PropTypes from "prop-types";
import React, { Component } from "react";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Accordion from "@/components/accordion";
import SecondaryHeadline from "@/components/headlines/club-detail/secondary";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";

import "./animations.module.scss";
import styles from "./index.module.scss";

class ComingSoonPresaleClub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAccordion: "0",
    };
    this.selectAccordionFunc = this.selectAccordionFunc.bind(this);
  }

  selectAccordionFunc(id) {
    this.setState({ selectedAccordion: id });
  }

  renderHeadlineSection() {
    const { isMobile } = this.props;

    return (
      <div className={isMobile ? "container" : " "}>
        <SecondaryHeadline text="120+ Classes Every Week" />
      </div>
    );
  }

  renderAccordion() {
    const {
      club: { comingSoonShowroomOpen },
    } = this.props;
    const { selectedAccordion } = this.state;

    if (!comingSoonShowroomOpen) {
      return <div />;
    }
    return comingSoonShowroomOpen.map((row, index) => {
      const { title, description } = row.fields;
      return (
        <Accordion
          accordionID={index.toString()}
          className={styles.comingSoonAccordion}
          key={title}
          label={title}
          selectAccordionFunc={this.selectAccordionFunc}
          selectedAccordion={selectedAccordion}
        >
          <span>{description}</span>
        </Accordion>
      );
    });
  }

  renderImage() {
    const { selectedAccordion } = this.state;
    const { isDesktop } = this.props;
    const {
      club: {
        comingSoonShowroomOpen,
        comingSoonShowroomOpenDefaultImage,
        comingSoonShowroomOpenDefaultImageMobile,
      },
    } = this.props;
    return (
      <div className={styles.imgContainer}>
        {comingSoonShowroomOpenDefaultImage &&
        comingSoonShowroomOpenDefaultImageMobile &&
        comingSoonShowroomOpenDefaultImage.fields &&
        comingSoonShowroomOpenDefaultImageMobile.fields ? (
          <>
            <img
              alt="Coming soon"
              className="d-lg-inline-block d-md-none d-sm-none d-xs-none"
              src={`${comingSoonShowroomOpenDefaultImage.fields.file.url}`}
            />
            <img
              alt="Coming soon"
              className="d-lg-none d-md-inline-block"
              src={`${comingSoonShowroomOpenDefaultImageMobile.fields.file.url}`}
            />
          </>
        ) : (
          <TransitionGroup component={null}>
            {comingSoonShowroomOpen &&
              comingSoonShowroomOpen.map((slide, index) => {
                const key = `${index}-image`;
                if (selectedAccordion != index) {
                  return null;
                }
                return (
                  <CSSTransition
                    classNames={{
                      enter: "item-enter",
                      enterActive: "item-enter-active",
                      enterDone: "item-enter-active",
                      exit: "item-exit",
                      exitActive: "item-exit-active",
                      exitDone: "item-exit-active",
                    }}
                    key={key}
                    timeout={500}
                  >
                    <>
                      {isDesktop ? (
                        <img
                          alt="Coming soon"
                          className="d-lg-inline-block d-md-none d-sm-none d-xs-none"
                          src={`${
                            slide.fields.image &&
                            slide.fields.image.fields.file.url
                          }`}
                        />
                      ) : (
                        <img
                          alt="Coming soon"
                          className="d-lg-none d-md-inline-block"
                          src={`${
                            slide.fields.imageMobile &&
                            slide.fields.imageMobile.fields.file.url
                          }`}
                        />
                      )}
                    </>
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        )}
      </div>
    );
  }

  render() {
    const { isMobile, isDesktop, inhibitAnimation } = this.props;
    const styleOverrides = {};
    let imageTransitionDuration = "70%";
    let titleTweenFrom = {
      css: {
        opacity: !isDesktop ? 1 : 0,
        top: !isDesktop ? "0px" : "250px",
      },
      ease: "Strong.easeInOut",
    };
    let titleTweenTo = {
      css: { opacity: 1, top: "0px" },
      ease: "Strong.easeInOut",
    };
    let classesTweenFrom = {
      css: {
        opacity: !isDesktop ? 1 : 0,
        position: "relative",
        top: !isDesktop ? "0px" : "250px",
      },
      ease: "Strong.easeInOut",
    };
    let classesTweenTo = {
      css: { opacity: 1, top: "0px" },
      ease: "Strong.easeInOut",
    };
    if (inhibitAnimation) {
      styleOverrides.paddingTop = "0px";
      imageTransitionDuration = "60%";
      titleTweenFrom = {
        css: {
          opacity: 1,
          top: "0px",
        },
        ease: "Strong.easeInOut",
      };
      titleTweenTo = {
        css: { opacity: 1, top: "0px" },
        ease: "Strong.easeInOut",
      };
      classesTweenFrom = {
        css: {
          position: "relative",
          top: !isDesktop ? "0px" : "120px",
        },
        ease: "Strong.easeInOut",
      };
      classesTweenTo = {
        css: { top: "0px" },
        ease: "Strong.easeInOut",
      };
    }
    return (
      <section
        className={`${styles.comingSoon}`}
        id="coming-soon"
        style={styleOverrides}
      >
        <div className={`${isMobile ? " " : "container"}`}>
          <div className="row align-items-start">
            <Controller key={`${isDesktop}-${isMobile}`}>
              <Scene duration="55%" triggerElement={`#coming-soon`}>
                {(progress) => {
                  return (
                    <Tween
                      from={titleTweenFrom}
                      paused
                      to={titleTweenTo}
                      totalProgress={progress}
                    >
                      <div
                        className={`${
                          isMobile ? "container" : " "
                        } col-lg-6 order-2 order-lg-1`}
                      >
                        {isDesktop ? this.renderHeadlineSection() : null}
                        {this.renderAccordion()}
                      </div>
                    </Tween>
                  );
                }}
              </Scene>
              <Scene
                duration={imageTransitionDuration}
                triggerElement="#coming-soon"
              >
                {(progress) => {
                  return (
                    <Tween
                      from={classesTweenFrom}
                      paused
                      to={classesTweenTo}
                      totalProgress={progress}
                    >
                      <div
                        className={`col-lg-7 offset-lg-3 order-1 order-lg-2 container`}
                      >
                        {!isDesktop ? this.renderHeadlineSection() : null}
                        {this.renderImage()}
                      </div>
                    </Tween>
                  );
                }}
              </Scene>
            </Controller>
          </div>
        </div>
      </section>
    );
  }
}

ComingSoonPresaleClub.propTypes = {
  club: PropTypes.object.isRequired,
  inhibitAnimation: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default ScreenDimensions(ComingSoonPresaleClub);
