import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import slugify from "slugify";

import Loader from "@/components/Loader";
import ComingSoonPresale from "@/components/club-detail/classes/ui-components/coming-soon-presale-club";
import OpenClub from "@/components/club-detail/classes/ui-components/open-club";

import styles from "./ClubClasses.module.scss";

const ClubClasses = ({
  data,
  facility,
  handleClassScheduleAcdlEvent,
  id,
  inhibitAnimation,
}) => {
  const [classes, setClasses] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { anchorTag, subNavLabel } = data;
  const sectionSlug = subNavLabel ? slugify(subNavLabel).toLowerCase() : id;

  useEffect(() => {
    const fetchClasses = async (facilityId) => {
      const dataResponse = await fetch(
        `/api/cms/facilities/${facilityId}/classes`
      );

      if (dataResponse?.ok) {
        const data = await dataResponse.json();
        setClasses(data);
      } else {
        setIsError(true);
      }

      setIsLoading(false);
    };

    if (facility?.facilityId) {
      fetchClasses(facility.facilityId);
    }
  }, [facility?.facilityId]);

  if (!isLoading && isError) {
    console.error("Error loading classes API data.");
    return null;
  }

  return (
    <section
      className={`${styles.classes} module`}
      data-attr-scroll={anchorTag}
      id={sectionSlug}
    >
      {isLoading && <Loader />}

      {!isLoading &&
        (facility.status === "Open" && classes?.classCategory?.length > 0 ? (
          <OpenClub
            classes={classes}
            club={data}
            clubType={facility.clubType}
            facilityId={facility.facilityId}
            handleClassScheduleAcdlEvent={handleClassScheduleAcdlEvent}
          />
        ) : (
          <ComingSoonPresale club={data} inhibitAnimation={inhibitAnimation} />
        ))}
    </section>
  );
};

ClubClasses.propTypes = {
  data: PropTypes.shape({
    anchorTag: PropTypes.string,
    comingSoonShowroomOpen: PropTypes.array,
    subNavLabel: PropTypes.string,
  }),
  facility: PropTypes.shape({
    clubType: PropTypes.string,
    facilityId: PropTypes.string,
    status: PropTypes.string,
  }),
  handleClassScheduleAcdlEvent: PropTypes.func,
  id: PropTypes.string,
  inhibitAnimation: PropTypes.bool,
};

export default ClubClasses;
