/**
 * Utilities is different then utils/logger
 * FIXME: Might want to consolidate the utils methods/functions
 *
 * Oddly it didn't like the webpack alias...
 */
import { format as formatDate } from "date-fns";

import { DATETIME } from "@/constants";

import logger from "../../../utils/logger";
import ApiClient from "../client";

const Classes = {
  namespace: "classes",

  basePathV6: "/v6/groupfitness/classes",
  hamptonsClassesBasePath: "/v6/groupfitness/hamptons/classes",
  hamptonsReservationBasePath: "/v6/groupfitness/hamptons/member",

  async findClassesByCategoryIds(categoryIds) {
    const endpoint = `${this.basePathV6}?categoryIds=${categoryIds}`;
    return ApiClient._get(endpoint).then(async (resp) => {
      if (!resp?.data) {
        logger.warn(
          `API|Classes: no classes found for category/ies with id/s ${categoryIds}`
        );
        return null;
      }
      const result = [];
      resp.data.forEach((catInfo) => {
        if (catInfo.classDetails) {
          catInfo.classDetails.forEach((classInfo) => {
            result.push({
              category: catInfo.categoryId,
              displayText: classInfo.className,
              id: classInfo.classId,
            });
          });
        }
      });
      return result;
    });
  },

  async findInstructorById(instructorId) {
    const endpoint = `${this.basePathV6}/instructors/?instructorIds=${instructorId}`;
    return ApiClient._get(endpoint).then(async (resp) => {
      const { data } = resp;
      if (!data) {
        logger.warn(`API|Classes: no instructor found with id ${instructorId}`);
        return null;
      }
      return data;
    });
  },

  async findAllClassesByFacilityIds(
    ids,
    startDate,
    endDate,
    isBookingRequired
  ) {
    const endpoint = `${this.basePathV6}/allclasses`;
    const data = {
      endDate,
      facilityIds: ids,
      isBookingRequired,
      startDate,
    };

    return ApiClient._post(endpoint, { data }).then(async (resp) => {
      if (!resp?.data?.classes) {
        logger.warn(`API|Classes: no classes found with ids: ${ids.join(",")}`);
        return null;
      }

      const { classes } = resp.data;
      const classIds = classes
        .map((entry) => {
          return entry.classId;
        })
        .filter((classId) => classId);

      if (classIds.length === 0) {
        return resp.data.classes;
      }

      const classEntries = [];

      classes.forEach((classInstance) => {
        const matchedClass = classEntries.filter((classEntry) => {
          return classEntry.id === classInstance.classId;
        });
        if (matchedClass.length > 0) {
          classInstance.classImage = matchedClass[0].image;
          classInstance.classImageMobile = matchedClass[0].mobileImage;
        } else {
          classInstance.classImage = null;
          classInstance.classImageMobile = null;
        }
      });

      return classes;
    });
  },

  async searchClasses(payload) {
    const endpoint = `${this.basePathV6}/allclasses`;

    try {
      const resp = await ApiClient._post(endpoint, {
        data: payload,
      });

      if (!resp.data) {
        logger.warn(`API|Search Classes: no return value`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`API|Search Classes: ${error?.response?.data || null}`);
      return error?.response?.data || null;
    }
  },

  async getHamptonClasses(
    startDate,
    endDate,
    workoutCategoryIds,
    timeOfDay,
    instructorIds
  ) {
    const startDateTime = formatDate(startDate, DATETIME.FORMAT_ISO_NO_MS);
    const endDateTime = formatDate(endDate, DATETIME.FORMAT_ISO_NO_MS);
    const endpoint =
      `${this.hamptonsClassesBasePath}?startDateTime=${startDateTime}&endDateTime=${endDateTime}` +
      `${
        workoutCategoryIds ? `&workoutCategoryIds=${workoutCategoryIds}` : ""
      }` +
      `${timeOfDay ? `&timeOfDay=${timeOfDay}` : ""}` +
      `${instructorIds ? `&instructorIds=${instructorIds}` : ""}`;

    try {
      const resp = await ApiClient._get(endpoint);

      if (!resp.data) {
        logger.warn(`API|Search Classes: no return value`);
        return null;
      }
      return resp.data;
    } catch (error) {
      logger.error(`API|Search Classes: ${error?.response?.data || null}`);
      return error?.response?.data || null;
    }
  },

  async bookHamptonClass(payload, token) {
    const endpoint = `${this.hamptonsReservationBasePath}/reservation`;

    try {
      const resp = await ApiClient._post(endpoint, {
        baseURL: ApiClient.config().equinox_api.v6_api_host,
        data: payload,
        headers: {
          Authorization: token,
        },
      });

      if (!resp.data) {
        logger.warn(`API|Search Classes: no return value`);
        return null;
      }

      return resp.data;
    } catch (error) {
      logger.error(`API|Search Classes: ${error?.response?.data || null}`);
      return error?.response?.data || null;
    }
  },
};

export default Classes;
